import { getCurrentInstance } from '@nuxtjs/composition-api';

const URLs = {
  // 各画面のURLのでの部分文字列

  // 会員
  users: 'users',

  // 初期登録
  sign_up: 'sign_up',

  // ログイン
  sign_in: 'sign_in',

  // ログアウト
  sign_out: 'sign_out',

  // マイページ
  my_page: 'my_page',

  // プロフィール
  profile: 'profile',

  // 基本情報
  base_information: 'base_information',

  // ＰＲ情報
  public_relations: 'public_relations',

  // ポートフォリオ
  portfolio: 'portfolio',

  // 企業オファー
  offers: 'offers',

  // 学校内掲示板
  notice_boards: 'notice_boards',

  // ブックマーク
  bookmarks: 'bookmarks',

  // 適性診断
  aptitude_diagnosis: 'aptitude_diagnosis',

  // 求人情報
  jobs: 'jobs',

  // 求人検索
  jobs_search: 'jobs_search',

  // 募集要項
  recruit_info: 'recruit_info',

  // 企業実習
  internships: 'internships',

  // 会社説明会
  seminars: 'seminars',

  entry: 'entry',

  // 注目記事
  feeds: 'feeds',

  // 活動記録
  activities: 'activities',

  // メッセージ
  messages: 'messages',

  // お知らせ
  notifications: 'notifications',

  // 設定
  settings: 'settings',

  // 活動状況
  activity_status: 'activity_status',

  // 志望条件
  desired_conditions: 'desired_conditions',

  // 企業情報
  corporations: 'corporations',

  // Eメールアドレス
  email: 'email',

  // パスワード
  password: 'password',

  // ソーシャル連携
  social_links: 'social_links',

  // 言語
  language: 'language',

  // 利用規約
  terms_of_use: 'terms_of_use',

  // プライバシーポリシー
  privacy_policy: 'privacy_policy',

  // トップページ
  get home() {
    return '/';
  },

  // 【表示】【IPhone・Android】【初期表示】
  get app_launch() {
    return '/app/launch';
  },

  // 【表示】【会員】【サインアップ】
  get show_sign_up() {
    return `/${this.users}/${this.sign_up}`;
  },

  // 【表示】【会員】【入力フォーム】
  get show_sign_up_form() {
    return `/${this.users}/${this.sign_up}/form`;
  },

  // 【表示】【会員】【サインイン】
  get show_sign_in() {
    return `/${this.users}/${this.sign_in}`;
  },

  // 【表示】【会員】【サインアウト】
  get show_sign_out() {
    return `/${this.users}/${this.sign_out}`;
  },

  // 【表示】【会員】【パスワード再発行】
  get show_user_new_password() {
    return `/${this.users}/password/new`;
  },

  // 【表示】【会員】【本登録メール再発行】
  get show_user_reconfirm() {
    return `/${this.users}/confirmations/reconfirm`;
  },

  // 【表示】【マイページ】【プロフィール】
  get show_profile() {
    return `/${this.my_page}/${this.profile}`;
  },

  // 【編集】【マイページ】【プロフィール(基本情報)】
  get edit_profile_base() {
    return `/${this.my_page}/${this.profile}/${this.base_information}/edit`;
  },

  // 【編集】【マイページ】【プロフィール(ＰＲ情報)】
  get edit_profile_pr() {
    return `/${this.my_page}/${this.profile}/${this.public_relations}/edit`;
  },

  // 【表示】【マイページ】【ポートフォリオ】
  show_portfolio_with_id(id: number | string = ':id') {
    return `/${this.my_page}/${this.profile}/${this.portfolio}/${id}`;
  },

  // 【編集】【マイページ】【ポートフォリオ】
  portfolio_edit(id: number | string = ':id?') {
    return `/${this.my_page}/${this.profile}/${this.portfolio}/${id}/edit`;
  },

  // 【登録】【マイページ】【ポートフォリオ】
  get new_portfolio() {
    return `/${this.my_page}/${this.profile}/${this.portfolio}/new`;
  },

  // 【詳細】【マイページ】【適性診断】
  show_aptitude_diagnosis_with_id(id: number | string = ':id?') {
    return `/${this.my_page}/${this.profile}/${this.aptitude_diagnosis}/${id}`;
  },

  // 【詳細】【マイページ】【適性診断】【自身の結果】
  get show_aptitude_diagnosis_self_result() {
    return `/${this.my_page}/${this.profile}/${this.aptitude_diagnosis}/result`;
  },

  // 【登録】【マイページ】【適性診断】
  get new_aptitude_diagnosis() {
    return `/${this.my_page}/${this.profile}/${this.aptitude_diagnosis}/new`;
  },

  // 【登録】【マイページ】【適性診断】
  show_request_diagnosis_with_id(id: string) {
    return `/userdata/diagnosis/${id}`;
  },

  // 【一覧】【マイページ】【オファー】
  get show_offers() {
    return `/${this.my_page}/${this.offers}`;
  },

  // 【詳細】【マイページ】【オファー】
  show_offer_with_id(id: number | string = ':id') {
    return `/${this.my_page}/${this.offers}/${id}`;
  },

  // 【一覧】【マイページ】【学内掲示板】
  get show_notice_boards() {
    return `/${this.my_page}/${this.notice_boards}`;
  },

  // 【詳細】【マイページ】【学内掲示板】
  show_notice_board_with_id(id: number | string = ':id?') {
    return `/${this.my_page}/${this.notice_boards}/${id}`;
  },

  // 【一覧】【マイページ】【ブックマーク】
  get show_bookmarks() {
    return `/${this.my_page}/${this.bookmarks}`;
  },

  // 【一覧】【求人情報】【募集情報】
  get show_recruit_info() {
    return `/${this.jobs}`;
  },

  // 【詳細】【求人情報】【募集情報】
  show_recruit_info_with_id(id: number | string = ':id') {
    return `/${this.jobs}/${id}`;
  },

  // 【一覧】【求人情報】【企業実習】
  get show_internships() {
    return `/${this.jobs}/${this.internships}`;
  },

  // 【詳細】【求人情報】【企業実習】
  show_internship_with_id(id: number | string = ':id') {
    return `/${this.jobs}/${this.internships}/${id}`;
  },

  // 【一覧】【求人情報】【会社説明会】
  get show_seminars() {
    return `/${this.jobs}/${this.seminars}`;
  },

  // 【詳細】【求人情報】【会社説明会】
  show_seminar_with_id(id: number | string = ':id') {
    return `/${this.jobs}/${this.seminars}/${id}`;
  },

  // 【詳細】【応募】【募集情報】
  show_entry_job_with_id(id: number | string = ':id') {
    return `${this.show_recruit_info_with_id(id)}/${this.entry}`;
  },

  // 【詳細】【応募】【企業実習】
  show_entry_internship_with_id(id: number | string = ':id') {
    return `${this.show_internship_with_id(id)}/${this.entry}`;
  },

  // 【詳細】【応募】【会社説明会】
  show_entry_seminar_with_id(id: number | string = ':id') {
    return `${this.show_seminar_with_id(id)}/${this.entry}`;
  },

  // 【検索条件設定】
  get show_jobs_search() {
    return `/${this.jobs_search}`;
  },
  // 【検索条件設定】【都道府県】
  get show_jobs_search_prefectures() {
    return `/${this.jobs_search}/prefectures`;
  },
  // 【検索条件設定】【市区町村】
  get show_jobs_search_cities() {
    return `/${this.jobs_search}/cities`;
  },
  // 【検索条件設定】【業種】
  get show_jobs_search_industries() {
    return `/${this.jobs_search}/industries`;
  },
  // 【検索条件設定】【職種】
  get show_jobs_search_categories() {
    return `/${this.jobs_search}/categories`;
  },
  // 【検索条件設定】【募集の特徴】
  get show_jobs_search_job_feature() {
    return `/${this.jobs_search}/job_feature`;
  },
  // 【検索条件設定】【企業のタイプ】
  get show_jobs_search_job_corporation_type() {
    return `/${this.jobs_search}/corporation_type`;
  },
  // 【検索条件設定】【企業実習】
  get show_jobs_search_internship() {
    return `/${this.jobs_search}/internship`;
  },
  // 【検索条件設定】【会社説明会】
  get show_jobs_search_seminar() {
    return `/${this.jobs_search}/seminar`;
  },

  // 【一覧】【注目記事】
  get show_feeds() {
    // return `/${this.feeds}`;
    return `/${this.feeds}/lesson`;
  },

  get show_feeds_lesson() {
    return `/${this.feeds}/lesson`;
  },

  get show_feeds_seniors() {
    return `/${this.feeds}/seniors`;
  },

  get show_feeds_corps() {
    return `/${this.feeds}/corps`;
  },

  // 【詳細】
  show_senior_article_with_id(id: number | string = ':id?') {
    return `/${this.feeds}/senior/${id}`;
  },

  // 【詳細】【注目記事】
  show_feed_with_id(id: number | string = ':id?') {
    return `/${this.feeds}/${id}`;
  },

  // 【一覧】【注目記事 新着】
  get show_feeds_sort_latest() {
    return `/${this.feeds}/sort/latest`;
  },

  get show_feeds_sort_corp_latest() {
    return `/${this.feeds}/sort/corp_latest`;
  },

  get show_feeds_sort_lesson_latest() {
    return `/${this.feeds}/sort/lesson_latest`;
  },

  // 【一覧】【注目記事 人気】
  get show_feeds_sort_popular() {
    return `/${this.feeds}/sort/popular`;
  },

  get show_feeds_sort_corp_popular() {
    return `/${this.feeds}/sort/corp_popular`;
  },

  get show_feeds_sort_lesson_popular() {
    return `/${this.feeds}/sort/lesson_popular`;
  },
  // 【一覧】【注目記事カテゴリー】
  get show_feed_categories() {
    return `/${this.feeds}/categories`;
  },

  // 【一覧】【注目記事カテゴリー一覧】
  show_feed_categories_with_id(id: number | string = ':id?') {
    return `/${this.feeds}/categories/${id}`;
  },

  // 【一覧】【活動記録】
  get show_activities() {
    return `/${this.activities}`;
  },

  // 【詳細】【活動記録】
  show_activity_with_id(id: number | string = ':id?') {
    return `/${this.activities}/${id}`;
  },

  // 【登録】【活動記録】
  get new_activities() {
    return `/${this.activities}/new`;
  },

  // 【編集】【活動記録】
  edit_activity_with_id(id: number | string = ':id?') {
    return `/${this.activities}/${id}/edit`;
  },

  // 【一覧】【メッセージ】
  get show_messages() {
    return `/${this.messages}`;
  },

  // 【詳細】【メッセージ】
  show_message_with_id(id: number | string = ':id?') {
    return `/${this.messages}/${id}`;
  },

  // 【詳細】企業ページ（概要）
  show_corporation_summary_with_id(id: number | string = ':id?') {
    return `/${this.corporations}/${id}`;
  },

  // 【詳細】企業ページ（注目記事）
  show_corporation_feeds_with_id(id: number | string = ':id?') {
    return `/${this.corporations}/${id}/feeds`;
  },

  // 【詳細】企業ページ（募集情報）
  show_corporation_jobs_with_id(id: number | string = ':id?') {
    return `/${this.corporations}/${id}/jobs`;
  },

  // 【詳細】企業ページ（社員）
  show_corporation_members_with_id(id: number | string = ':id?') {
    return `/${this.corporations}/${id}/members`;
  },

  // 【詳細】社員詳細ページ
  show_corporation_member_with_member_id(
    id: number | string = ':corporationId?',
    memberId: number | string = ':memberId?'
  ) {
    return `/${this.corporations}/${id}/members/${memberId}`;
  },

  // 【詳細】卒業生ページ
  show_school_graduats_with_member_id(
    id: number | string = ':corporationId?',
    memberId: number | string = ':memberId?'
  ) {
    return `/${this.corporations}/${id}/graduates/${memberId}`;
  },

  // ▲▲▲▲▲▲▲▲▲▲▲ こっから上は、整理済み ▲▲▲▲▲▲▲▲▲▲▲▲▲▲

  // 検索設定
  get searchSettings() {
    return '/search_settings';
  },

  // 検索設定（都道府県）
  get searchSettingPreefcture() {
    return '/search_settings/prefecture';
  },

  // パスワード再発行
  get passwordReset() {
    return '/users/password/new';
  },

  // 本登録メール再送信
  get confirmationMail() {
    return '/users/confirmations/reconfirm';
  },

  // ▼▼▼▼▼▼▼▼▼▼▼ こっから下は、整理済み ▼▼▼▼▼▼▼▼▼▼▼▼▼▼
  // 【一覧】【お知らせ】
  get show_notifications() {
    return `/${this.notifications}`;
  },

  // 【詳細】【お知らせ】
  show_notification_with_id(id: number) {
    return `/${this.notifications}/${id}`;
  },

  // 【お知らせ】【メッセージ詳細】
  show_notification_message_with_id(id: number | string = ':id?') {
    return `/${this.notifications}/${this.messages}/${id}`;
  },

  // 【お知らせ】【学内掲示板詳細】
  show_notification_notice_board_with_id(id: number | string = ':id?') {
    return `/${this.notifications}/${this.notice_boards}/${id}`;
  },

  // 【お知らせ】【オファー詳細】
  show_notification_offer_with_id(id: number | string = ':id?') {
    return `/${this.notifications}/${this.offers}/${id}`;
  },

  // 【表示】【設定】
  get show_settings() {
    return `/${this.settings}`;
  },

  // 【編集】【設定】【活動状況】
  get edit_activity_status() {
    return `/${this.settings}/${this.activity_status}/edit`;
  },

  // 【編集】【設定】【志望条件】
  get edit_desired_conditions() {
    return `/${this.settings}/${this.desired_conditions}/edit`;
  },

  // 【編集】【設定】【メールアドレス】
  get edit_email() {
    return `/${this.settings}/${this.email}/edit`;
  },

  // 【編集】【設定】【パスワード】
  get edit_password() {
    return `/${this.settings}/${this.password}/edit`;
  },

  // 【編集】【設定】【ソーシャル連携】
  get edit_social_links() {
    return `/${this.settings}/${this.social_links}/edit`;
  },

  // 【編集】【設定】【使用言語】
  get edit_language() {
    return `/${this.settings}/${this.language}/edit`;
  },

  // 【表示】【設定】【利用規約】
  get show_terms_of_use() {
    return `/${this.settings}/${this.terms_of_use}`;
  },

  // 【表示】【設定】【プライバシーポリシー】
  get show_privacy_policy() {
    return `/${this.settings}/${this.privacy_policy}`;
  },

  // 【表示】【バイパス】【推奨ブラウザ一覧】
  get recommended_browsers() {
    return '/bypass/recommend_other_browsers';
  },

  // 【表示】証明書発行
  show_apply_certificate(schoolNumber: string) {
    return `/certificate_applications/apply/${schoolNumber}`;
  },
};

export default function useAppRoute() {
  const instance = getCurrentInstance()?.proxy;
  const redirect = (url: string, force: boolean = false) => {
    instance?.$router.push(url, () => {
      if (force) {
        location.reload();
      }
    });
  };
  return {
    URLs,
    redirect,
  };
}
