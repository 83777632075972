import { Context } from '@nuxt/types';
import { getCurrentInstance } from '@nuxtjs/composition-api';
import dayjs from 'dayjs';

export enum CookieKeys {
  USER_UUID = 'user_uuid',
  CREDENTIALS = 'credentials',
  APP_ENV = 'app_env',
  NotificationCount = 'notification_count',
  SchoolInfo = 'SchoolInfo',
  JobSettings = 'JobSettings',
  JobSettingsTemporary = 'JobSettingsTemporary',
  JobFreeWordHistory = 'JobFreeWordHistory',
  SeminarFreeWordHistory = 'SeminarFreeWordHistory',
  InternshipFreeWordHistory = 'InternshipFreeWordHistory',
  SeminarSettings = 'SeminarSettings',
  SeminarSettingsTemporary = 'SeminarSettingsTemporary',
  InternshipSettings = 'InternshipSettings',
  InternshipSettingsTemporary = 'InternshipSettingsTemporary',
  FeedSettings = 'FeedSettings',
  ModalFirstOffer = 'ModalFirstOffer',
}

interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | 'none' | 'lax' | 'strict';
  encode?: (value: string) => string;
}

interface UseCookieOptions {
  context?: Context;
}

export default function useCookie(options?: UseCookieOptions) {
  const instance = options?.context?.app || getCurrentInstance()?.proxy;
  const cookie = instance?.$cookie;
  const today = dayjs();
  const cookieOptionsDefault = {
    path: '/',
    expires: today.add(1, 'week').toDate(),
  };
  const setCookie = (
    name: string,
    value: any,
    options: CookieSetOptions = cookieOptionsDefault
  ) => {
    cookie?.set(name, value, options);
  };
  const getCookie = (name: string, options = {}) => {
    return cookie?.get(name, options);
  };
  const removeCookie = (name: string) => {
    cookie?.remove(name, {
      path: '/',
    });
  };
  return {
    setCookie,
    getCookie,
    removeCookie,
  };
}
